<template>
  <div class="docking-config">
    <div class="list-content" v-loading="loading">
      <div class="config-item" v-if="config">
        <div class="config-title">对接人配置</div>
        <div class="config-content">
          <el-form class="small-form" size="small" label-width="70px" label-position="left">
            <el-form-item label="选择用户">
              <member-tag v-for="(t, i) in config" :key="t.user_id" @del="config.splice(i, 1)">
                {{t.nickname}}（{{t.title_name}}）</member-tag>
              <el-button class="member-btn" type="primary" @click="member.show = true">+ 添加</el-button>
              <div class="form-tip">
                <span class="el-icon-info" style="font-size: 15px; color:#C0C4CC"></span>
                <span>选择的会员将被随机分配至预备会员的对接人</span>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <fixed-action-bar>
      <el-button type="primary" :loading="loading" @click="saveConfig">保存</el-button>
    </fixed-action-bar>
    <select-member v-model="member.show" :members="config || []" @changeSelect="handleSelect"></select-member>
  </div>
</template>

<script>
import FixedActionBar from "../../../../base/layout/FixedActionBar";
import { getDockingConfig, saveDockingConfig } from "../../api/other-config";
import SelectMember from "@/modules/common/components/MemberSelector";
import MemberTag from "../../components/OtherConfig/MemberTag";
export default {
  components: { MemberTag, SelectMember, FixedActionBar },
  data() {
    return {
      loading: true,
      config: null,
      member: {
        show: false,
      },
    };
  },
  created() {
    this.getConfig();
  },
  methods: {
    handleSelect(e) {
      console.log(e)
      this.config = e.map((item) => {
        return {
          nickname: item.nickname,
          title_name: item.title_name,
          user_id: item.user_id,
        };
      });
    },
    saveConfig() {
      this.loading = true;
      saveDockingConfig({
        config: this.config,
      })
        .then((res) => {
          this.$message.success(res.msg);
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    getConfig() {
      this.loading = true;
      getDockingConfig()
        .then((res) => {
          this.config = res.data || [];
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.docking-config {
  .list-item {
    min-height: 100px;
  }

  .config-item + .config-item {
    margin-top: 79px;
  }

  .config-item {
    padding-top: 20px;
    .config-title {
      padding-left: 8px;
      font-size: 16px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: 16px;
      border-left: 3px #3479ef solid;
    }
    .config-content {
      margin-top: 32px;
      padding-left: 11px;
      .switch-config {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        .form-title {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.85);
          line-height: 14px;
          margin-right: 15px;
        }
      }
      .form-tip {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #999;
        line-height: 12px;
        span + span {
          margin-left: 4px;
        }
      }
      .suffix {
        line-height: 34px;
        margin-left: 12px;
        color: rgba(0, 0, 0, 0.85);
      }

      .el-input-number {
        max-width: 140px;
      }
    }
  }

  .member-tag,
  .member-btn {
    margin-right: 12px;
    margin-bottom: 12px;
  }
}
</style>
