import { render, staticRenderFns } from "./docking-config.vue?vue&type=template&id=d06298e0&scoped=true&"
import script from "./docking-config.vue?vue&type=script&lang=js&"
export * from "./docking-config.vue?vue&type=script&lang=js&"
import style0 from "./docking-config.vue?vue&type=style&index=0&id=d06298e0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d06298e0",
  null
  
)

export default component.exports